import Routes from "./routes";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import LoginInfoContext from "./LoginContext";

function App() {
  return (
    <LoginInfoContext>
      <div className="App">
        <Routes />
        <ToastContainer />
      </div>
    </LoginInfoContext>
  );
}

export default App;
