// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyADIhogCBHmAY7PE1dawhw3D8yKKzOEaI8",
  authDomain: "i-smart-lib.firebaseapp.com",
  projectId: "i-smart-lib",
  storageBucket: "i-smart-lib.appspot.com",
  messagingSenderId: "449013760363",
  appId: "1:449013760363:web:84249b8aabd62e1a050c2e",
  measurementId: "G-HQPFRVS506"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth();

const db = getFirestore(app);

export { app, auth, db };
