import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./Home";
import Loader from "../Loader";

const Login = React.lazy(() => import("../Login"));
const PrivateRoute = React.lazy(() => import("../PrivateRoute"));
const NewStudent = React.lazy(() => import("../routes/NewStudent"));
const AllUsers = React.lazy(() => import("../routes/AllUsers"));
const UserDetailPage = React.lazy(() => import("../routes/UserDetail"));
const EntryPoint = React.lazy(() => import("../routes/EntryPoint"));

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <div>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/i-smart/entry" element={<PrivateRoute Component={EntryPoint} />} />
            <Route
              path="/i-smart/add-new-student"
              element={<PrivateRoute Component={NewStudent} />}
            />
            <Route
              path="/i-smart/all-users"
              element={<PrivateRoute Component={AllUsers} />}
            />
            <Route
              path="/i-smart/user-detail/:id"
              element={<PrivateRoute Component={UserDetailPage} />}
            />
          </Routes>
        </Suspense>
      </div>
    </BrowserRouter>
  );
};

export default AppRoutes;
