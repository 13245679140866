import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../firebase";

export const LoginInfoContext = createContext({});

const UserInfoContext = ({ children }) => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  }, []);

  const logoutUser = useCallback(() => {
    signOut(auth)
      .then(() => {
        window.location.assign("/login");
      })
      .catch((error) => {
        // An error happened.
      });
  }, []);

  const userInfo = useMemo(
    () => ({
      isLoggedInUser: Boolean(user),
      logoutUser,
    }),
    [logoutUser, user]
  );

  return <LoginInfoContext.Provider value={userInfo}>{children}</LoginInfoContext.Provider>;
};

export default UserInfoContext;
